import React from "react"
import { Box, Text, Flex, SimpleGrid } from "@chakra-ui/layout"

import HeaderLogo from "../header/logo"
import ResponsiveBlock from "../responsive-block"
import LinkColumn from "./link-column"
import { About, Compare, Socials } from "./links"
import GradientDivider from "~components/shared/gradient-divider"
import { useBreakpointValue } from "@chakra-ui/react"
import CallToAction from "./call-to-action"

interface Props {
    useChckvet?: boolean
}

export default function Footer({ useChckvet }: Props) {
    const isCompareDoubled = useBreakpointValue({ base: false, xl: true })

    return (
        <>
            <CallToAction />
            <Box bg="navy.800">
                <ResponsiveBlock isWide pt={12} pb={6}>
                    <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 4, xl: 5 }}
                        rowGap={{ base: 16, xl: 0 }}
                    >
                        <HeaderLogo useColoredLogo useChckvet={useChckvet} />
                        <LinkColumn title="About us" links={About} />
                        <LinkColumn
                            title={`Compare ${
                                useChckvet ? "Chckvet" : "Chckup"
                            }`}
                            links={Compare}
                            double={isCompareDoubled}
                        />
                        <LinkColumn title="Socials" links={Socials} />
                    </SimpleGrid>
                    <GradientDivider
                        bgGradient="linear(to-r, whiteAlpha.50, whiteAlpha.300, whiteAlpha.50)"
                        mt={10}
                        mb={6}
                    />
                    <Flex justify="space-between">
                        <Text fontSize="xs" color="navy.50" fontWeight="medium">
                            Chckup, Inc.
                        </Text>
                    </Flex>
                </ResponsiveBlock>
            </Box>
        </>
    )
}
